var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      !_vm.isMobile()
        ? _c(
            "div",
            [
              _c("v-img", {
                staticClass: "rounded-lg cursor",
                attrs: {
                  src: require("@/assets/images/hartman-web.jpg"),
                  alt: "Hartman-slidshow",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.AssignQuestion()
                },
              },
            },
            [
              _c("v-img", {
                staticClass: "w-100 rounded-lg cursor",
                attrs: {
                  src: require("@/assets/images/hartman-mobile.png"),
                  alt: "Hartman-slidshow",
                },
              }),
            ],
            1
          ),
      _vm.slideshow.length
        ? _c(
            "div",
            { staticClass: "rounded-lg overflow-hidden" },
            [
              _c(
                "v-carousel",
                {
                  attrs: {
                    height: "400",
                    "hide-delimiter-background": "",
                    "delimiter-icon": "mdi-circle",
                    continuous: true,
                    cycle: true,
                    "show-arrows-on-hover": "",
                    interval: 3000,
                    dark: "",
                  },
                },
                _vm._l(_vm.slideshow, function (item, i) {
                  return _c("v-carousel-item", {
                    key: i,
                    attrs: {
                      src: item.coverPic,
                      "reverse-transition": "fade-transition",
                      transition: "fade-transition",
                    },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.moods && _vm.moods.length > 0
        ? _c("div", { staticClass: "d-flex justify-content-between mt-5" }, [
            _c("div", {}, [
              _c("span", { staticClass: "f14 fw-bold" }, [
                _vm._v(_vm._s(_vm.$t("Activity status"))),
              ]),
            ]),
            _c(
              "div",
              [
                _c("v-select", {
                  staticClass: "rounded-pill fit",
                  attrs: {
                    items: _vm.option,
                    solo: "",
                    dense: "",
                    "hide-details": "",
                  },
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.moods && _vm.moods.length > 0
        ? _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.push(`/c/moods`)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("LineChartGenerator", {
                    attrs: {
                      "chart-options": _vm.chartOptions,
                      "chart-data": _vm.chartData,
                      "chart-id": _vm.chartId,
                      "dataset-id-key": _vm.datasetIdKey,
                      plugins: _vm.plugins,
                      "css-classes": _vm.cssClasses,
                      styles: _vm.styles,
                      width: _vm.width,
                      height: _vm.height,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "mt-5",
              attrs: { role: "button" },
              on: {
                click: function ($event) {
                  return _vm.$router.push(`/c/moods`)
                },
              },
            },
            [
              _c("v-img", {
                staticClass: "w-100 rounded-lg",
                attrs: {
                  src: require("@/assets/images/mood_placeholder.gif"),
                  fluid: "",
                  alt: "mood_placeholder",
                },
              }),
            ],
            1
          ),
      _vm.exams && _vm.exams.length > 0
        ? _c("div", [
            _c("div", { staticClass: "d-flex justify-content-between mt-5" }, [
              _c("div", [
                _c("span", { staticClass: "f14 font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("Exams"))),
                ]),
              ]),
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "text-decoration-none primary--text fw-bold",
                      attrs: { to: "/c/exams" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "f12", attrs: { role: "button" } },
                        [_vm._v(_vm._s(_vm.$t("Show all")))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "d-flex flex-row" },
              [
                _c(
                  "v-slide-group",
                  {
                    staticClass: "py-4",
                    attrs: { "center-active": "", "show-arrows": "" },
                  },
                  _vm._l(_vm.exams, function (item, i) {
                    return _c(
                      "v-slide-item",
                      { key: i, staticClass: "my-2" },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass:
                              "p-3 bg-white rounded mx-2 d-flex flex-row align-items-center justify-content-start border",
                            attrs: { "min-width": "250", flat: "" },
                            on: {
                              click: function ($event) {
                                return _vm.handleClickExam(item)
                              },
                            },
                          },
                          [
                            item.elogo
                              ? _c("v-avatar", { attrs: { size: "40" } }, [
                                  _c("img", {
                                    attrs: {
                                      src: item.elogo,
                                      alt: item.etitle,
                                    },
                                  }),
                                ])
                              : _c("b-icon", {
                                  staticClass: "bg-light pa-3 rounded-circle",
                                  attrs: {
                                    icon: "book",
                                    width: "40",
                                    height: "40",
                                    variant: "primary",
                                  },
                                }),
                            _c("div", { staticClass: "ms-3" }, [
                              _c(
                                "span",
                                { staticClass: "d-block f14 fw-bold" },
                                [_vm._v(_vm._s(item.etitle))]
                              ),
                              _c(
                                "span",
                                { staticClass: "d-block f13 grey--text" },
                                [
                                  _vm._v(
                                    _vm._s(item.drFirstName) +
                                      " " +
                                      _vm._s(item.drLastName)
                                  ),
                                ]
                              ),
                              item.estatus == "PAID" || item.estatus == "FREE"
                                ? _c("div", [
                                    item.estatus == "FREE"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block primary--text f12",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("FREE")) +
                                                " (" +
                                                _vm._s(_vm.$t("Not answered")) +
                                                ") "
                                            ),
                                          ]
                                        )
                                      : item.estatus == "PAID"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block primary--text f12",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("PAID")) +
                                                " (" +
                                                _vm._s(_vm.$t("Not answered")) +
                                                ") "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : item.estatus == "PAYMENT_PENDING"
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "red--text f12" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("PAYMENT PENDING")) +
                                            " (" +
                                            _vm._s(
                                              _vm._f("VMask")(
                                                item.eprice || 0,
                                                _vm.currencyMask
                                              )
                                            ) +
                                            " " +
                                            _vm._s(_vm.$t("RIAL")) +
                                            ") "
                                        ),
                                      ]
                                    ),
                                  ])
                                : item.estatus == "ANSWERED"
                                ? _c(
                                    "span",
                                    { staticClass: "d-block green--text f12" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("ANSWERED")) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.exercises && _vm.exercises.length > 0
        ? _c("div", [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between mt-5" },
              [
                _c("div", [
                  _c("span", { staticClass: "f14 font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t("Training"))),
                  ]),
                ]),
                _c(
                  "router-link",
                  {
                    staticClass: "text-decoration-none primary--text fw-bold",
                    attrs: { to: "/c/exercises" },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "f12", attrs: { role: "button" } },
                      [_vm._v(_vm._s(_vm.$t("Show all")))]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-row" },
              [
                _c(
                  "v-slide-group",
                  {
                    staticClass: "py-4",
                    attrs: { "center-active": "", "show-arrows": "" },
                  },
                  _vm._l(_vm.exercises, function (item, i) {
                    return _c(
                      "v-slide-item",
                      { key: i, staticClass: "my-2" },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass:
                              "p-3 bg-white rounded mx-2 d-flex flex-row align-items-center justify-content-start border",
                            attrs: { flat: "", "min-width": "250" },
                            on: {
                              click: function ($event) {
                                return _vm.handleClickExercise(item)
                              },
                            },
                          },
                          [
                            item.elogo
                              ? _c("v-avatar", { attrs: { size: "40" } }, [
                                  _c("img", {
                                    attrs: {
                                      src: item.elogo,
                                      alt: item.etitle,
                                    },
                                  }),
                                ])
                              : _c("b-icon", {
                                  staticClass: "bg-light pa-3 rounded-circle",
                                  attrs: {
                                    icon: "book",
                                    width: "40",
                                    height: "40",
                                    variant: "primary",
                                  },
                                }),
                            _c("div", { staticClass: "ms-3" }, [
                              _c(
                                "span",
                                { staticClass: "d-block f14 fw-bold" },
                                [_vm._v(_vm._s(item.etitle))]
                              ),
                              _c(
                                "span",
                                { staticClass: "d-block f13 grey--text" },
                                [
                                  _vm._v(
                                    _vm._s(item.drFirstName) +
                                      " " +
                                      _vm._s(item.drLastName)
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.doctors != null
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "mt-5 d-flex justify-content-between" },
                [
                  _c("div", [
                    _c("span", { staticClass: "f14 font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$t("Latest specialists"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "text-decoration-none primary--text fw-bold",
                          attrs: { to: "/c/specialists" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "f12", attrs: { role: "button" } },
                            [_vm._v(_vm._s(_vm.$t("Show all")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-sheet",
                { staticClass: "mt-4", attrs: { elevation: "0" } },
                [
                  _c(
                    "v-slide-group",
                    { attrs: { "show-arrows": "" } },
                    _vm._l(_vm.doctors, function (item, i) {
                      return _c(
                        "v-slide-item",
                        { key: i },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "ma-4 text-center",
                              attrs: { flat: "" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openDrBottomSheet(item.mobile)
                                },
                              },
                            },
                            [
                              _c(
                                "v-avatar",
                                { attrs: { size: "72" } },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      "lazy-src": require("@/assets/images/profile-circle.png"),
                                      src:
                                        item.pic != null
                                          ? item.pic
                                          : require("@/assets/images/profile.jpg"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "f15 max-3-line mt-4" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.firstname) +
                                      " " +
                                      _vm._s(item.lastname) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.questions && _vm.questions.length > 0
        ? _c("div", { staticClass: "mt-5 d-flex justify-content-between" }, [
            _c("div", [
              _c("span", { staticClass: "f14 font-weight-bold" }, [
                _vm._v(_vm._s(_vm.$t("Frequently questions"))),
              ]),
            ]),
            _c(
              "div",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "text-decoration-none primary--text fw-bold",
                    attrs: { to: "/c/questions" },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "f12", attrs: { role: "button" } },
                      [_vm._v(_vm._s(_vm.$t("Show all")))]
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.questions && _vm.questions.length > 0
        ? _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "v-carousel",
                {
                  attrs: {
                    height: "auto",
                    cycle: "",
                    "hide-delimiter-background": "",
                    "show-arrows": false,
                    interval: 4000,
                    light: "",
                  },
                },
                _vm._l(_vm.questions, function (item, i) {
                  return _c(
                    "v-carousel-item",
                    { key: i },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "mx-2 mt-2 mb-12 d-flex flex-column justify-space-between border",
                          attrs: {
                            elevation: "0",
                            hover: "",
                            ripple: "",
                            to: `/c/questions/${item.id}`,
                          },
                        },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "d-flex justify-space-between align-items-center",
                            },
                            [
                              item.answers
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.answers.length) +
                                          " " +
                                          _vm._s(_vm.$t("Answer"))
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12",
                                    },
                                    [_vm._v(" 0 " + _vm._s(_vm.$t("Answer")))]
                                  ),
                              _c(
                                "span",
                                { staticClass: "f13 font-weight-light" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          item.createDate,
                                          "from",
                                          "now"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("v-card-text", [
                            _c("p", {
                              staticClass: "f14 text-justify",
                              domProps: { innerHTML: _vm._s(item.question) },
                            }),
                          ]),
                          _c("v-divider", {
                            staticClass: "m-0",
                            attrs: { dark: "" },
                          }),
                          _c(
                            "v-card-actions",
                            {
                              staticClass:
                                "d-flex justify-space-between pt-2 align-center",
                            },
                            [
                              item.answers && item.answers.length
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row align-items-center",
                                      },
                                      [
                                        _c(
                                          "section",
                                          {
                                            staticClass:
                                              "d-flex flex-row avatar-group right-to-left",
                                          },
                                          _vm._l(
                                            item.answers.slice(0, 3),
                                            function (answer, index) {
                                              return _c(
                                                "span",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "avatars-group__item avatar",
                                                },
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    {
                                                      staticClass: "bordered",
                                                      attrs: {
                                                        size: "36",
                                                        color: "primary",
                                                      },
                                                    },
                                                    [
                                                      answer.doctorProfilePic &&
                                                      index < 2
                                                        ? _c("img", {
                                                            attrs: {
                                                              src: answer.doctorProfilePic,
                                                              alt: answer.doctorFullName
                                                                ? answer.doctorFullName
                                                                : "",
                                                            },
                                                          })
                                                        : index < 2
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "white--text f12",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    answer.doctorFullName
                                                                      ? answer.doctorFullName.slice(
                                                                          0,
                                                                          1
                                                                        )
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "white--text f14",
                                                            },
                                                            [_vm._v(" ... ")]
                                                          ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c("div", [
                                          item.answers.length == 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "f12 font-weight-normal ps-1",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.answers[0]
                                                          .doctorFullName
                                                          ? item.answers[0]
                                                              .doctorFullName
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          item.answers.length > 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "f12 font-weight-normal ps-1",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.answers.length
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$t("specialist")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "bg-harmonies text-paris-pink p-2 rounded-lg f12",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("Not answered")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                              _c("div", [
                                _c("div", { staticClass: "text-left mt-1" }, [
                                  item.userLikeQuestions &&
                                  !item.userLikeQuestions.find(
                                    (x) =>
                                      x.mobile ==
                                      _vm.$store.getters.mobileNumber
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex d-inline-flex align-items-center border border-primary rounded primary--text",
                                        },
                                        [
                                          _c("iconly", {
                                            staticClass: "ms-1 me-0 wh-20",
                                            attrs: {
                                              type: "bold",
                                              name: "heart",
                                            },
                                          }),
                                          item.userLikeQuestions &&
                                          item.userLikeQuestions.length
                                            ? _c(
                                                "span",
                                                { staticClass: "me-1 f12" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.userLikeQuestions
                                                          .length
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "me-1 f12" },
                                                [_vm._v("0")]
                                              ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex d-inline-flex align-items-center primary rounded white--text",
                                        },
                                        [
                                          _c("iconly", {
                                            staticClass: "ms-1 me-0 wh-20",
                                            attrs: {
                                              type: "bold",
                                              name: "heart",
                                              color: "white",
                                            },
                                          }),
                                          item.userLikeQuestions &&
                                          item.userLikeQuestions.length
                                            ? _c(
                                                "span",
                                                { staticClass: "me-1 f12" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.userLikeQuestions
                                                          .length
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "me-1 f12" },
                                                [_vm._v("0")]
                                              ),
                                        ],
                                        1
                                      ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex d-inline-flex align-items-center border border-primary rounded primary--text ms-2",
                                    },
                                    [
                                      _c("iconly", {
                                        staticClass: "ms-1 me-0 wh-20",
                                        attrs: { type: "bold", name: "show" },
                                      }),
                                      _c("span", { staticClass: "me-1 f12" }, [
                                        _vm._v(_vm._s(item.userViews.length)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.explore && _vm.explore.length > 0
        ? _c("div", [
            _c("div", { staticClass: "mt-0 d-flex justify-content-between" }, [
              _c("div", [
                _c("span", { staticClass: "f14 font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("Last posts"))),
                ]),
              ]),
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "text-decoration-none primary--text fw-bold",
                      attrs: { to: "/c/contents" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "f12", attrs: { role: "button" } },
                        [_vm._v(_vm._s(_vm.$t("Show all")))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              [
                _c(
                  "v-slide-group",
                  { attrs: { "show-arrows": true } },
                  _vm._l(_vm.explore, function (item, i) {
                    return _c(
                      "v-slide-item",
                      { key: i },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "ma-3 border rounded-lg",
                            attrs: {
                              height: "auto",
                              to: `/c/contents/${item.type.toLowerCase()}/${
                                item.id
                              }`,
                              elevation: "0",
                            },
                          },
                          [
                            _c(
                              "v-img",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-center",
                                attrs: {
                                  "lazy-src": require("@/assets/images/placeholder.png"),
                                  src: item.picture,
                                  "aspect-ratio": 1 / 1,
                                  width: "250",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-white w-100 text-center",
                                  },
                                  [
                                    item.type == "VIDEO"
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-play p-4 bg-black-50 rounded-pill",
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-card-title",
                              { staticClass: "f13 single-line pb-0" },
                              [_vm._v(" " + _vm._s(item.title) + " ")]
                            ),
                            _c(
                              "v-card-actions",
                              {
                                staticClass: "pt-0",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openDrBottomSheet(item.publisher)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass: "p-0",
                                    attrs: { "two-line": "" },
                                  },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            "lazy-src": require("@/assets/images/profile-circle.png"),
                                            src: item.publisherPic,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "f12 font-weight-bold primary--text",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.publisherName) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-list-item-subtitle",
                                          { staticClass: "f10" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("moment")(
                                                  item.createDate,
                                                  "from",
                                                  "now"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.podcasts && _vm.podcasts.length > 0
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "mt-5 d-flex justify-content-between" },
                [
                  _c("div", [
                    _c("span", { staticClass: "f14 font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$t("Last podcasts"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "text-decoration-none primary--text fw-bold",
                          attrs: { to: "/c/podcasts" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "f12", attrs: { role: "button" } },
                            [_vm._v(_vm._s(_vm.$t("Show all")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-sheet",
                { staticClass: "mt-4", attrs: { flat: "" } },
                [
                  _c(
                    "v-slide-group",
                    { attrs: { "show-arrows": "" } },
                    _vm._l(_vm.podcasts, function (item, i) {
                      return _c(
                        "v-slide-item",
                        { key: i },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "d-flex flex-row align-items-center mb-2 pe-2 ps-0 border rounded-lg",
                              attrs: {
                                to: `/c/contents/podcast/${item.id}`,
                                elevation: "0",
                                "min-width": "300",
                              },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  "lazy-src": require("@/assets/images/placeholder.png"),
                                  src:
                                    item.picture != null
                                      ? item.picture
                                      : require("@/assets/images/placeholder.png"),
                                  "aspect-ratio": 1 / 1,
                                  "max-width": "150",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "d-flex flex-column ms-4 me-0" },
                                [
                                  _c("p", {
                                    staticClass: "f14 font-weight-bold",
                                    domProps: { innerHTML: _vm._s(item.title) },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "f14 font-weight-bold text-blue",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openDrBottomSheet(
                                            item.publisher
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.publisherName))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "f13 font-weight-light align-items-center d-flex grey--text",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fal fa-clock me-1 ms-0",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              item.createDate,
                                              "from",
                                              "now"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("BshDoctor", { ref: "drBottomSheet" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }