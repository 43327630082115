<template>
  <section>

    <!-- ---------------------------------------------------------------------- -->
    <!-- Slideshow section -->
    <!-- ---------------------------------------------------------------------- -->
    <div v-if="!isMobile()">
      <v-img :src="require('@/assets/images/hartman-web.jpg')" alt="Hartman-slidshow" class="rounded-lg cursor"></v-img>
    </div>

    <div v-else @click="AssignQuestion()">
      <v-img :src="require('@/assets/images/hartman-mobile.png')" alt="Hartman-slidshow"
        class="w-100 rounded-lg cursor"></v-img>
    </div>
    <div class="rounded-lg overflow-hidden" v-if="slideshow.length">
      <v-carousel 
        height="400" 
        hide-delimiter-background
        delimiter-icon="mdi-circle"
        :continuous="true"
        :cycle="true"
        show-arrows-on-hover
        :interval="3000"
        dark
      >
        <v-carousel-item 
          v-for="(item, i) in slideshow" 
          :key="i" 
          :src="item.coverPic" 
          reverse-transition="fade-transition" 
          transition="fade-transition"
        >
<!--          <div style="position: relative; top: 80%" class=" mx-3 bg-o70 white&#45;&#45;text px-3 py-1 rounded-lg f15" v-if="item.title">-->
<!--            {{ item.title }}-->
<!--          </div>-->
        </v-carousel-item>
      </v-carousel>
    </div>

    <!-- ---------------------------------------------------------------------- -->
    <!-- Mood section -->
    <!-- ---------------------------------------------------------------------- -->

    <div class="d-flex justify-content-between mt-5" v-if="moods && moods.length > 0">
      <div class="">
        <span class="f14 fw-bold">{{ $t("Activity status") }}</span>
      </div>
      <div>
        <v-select v-model="selected" :items="option" solo dense class="rounded-pill fit" hide-details>
        </v-select>
      </div>
    </div>
    <div v-if="moods && moods.length > 0" @click="$router.push(`/c/moods`)">
      <div class="mt-2" >
        <LineChartGenerator 
          :chart-options="chartOptions" 
          :chart-data="chartData" 
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey" 
          :plugins="plugins" 
          :css-classes="cssClasses" 
          :styles="styles" 
          :width="width"
          :height="height" />
      </div>
    </div>
    <div class="mt-5" role="button" v-else @click="$router.push(`/c/moods`)">
      <v-img :src="require('@/assets/images/mood_placeholder.gif')" fluid alt="mood_placeholder" class="w-100 rounded-lg">
      </v-img>
    </div>

    <!-- ---------------------------------------------------------------------- -->
    <!-- Exams -->
    <!-- ---------------------------------------------------------------------- -->
    <div v-if="exams && exams.length > 0">
      <div class="d-flex justify-content-between mt-5">
        <div>
          <span class="f14 font-weight-bold">{{ $t("Exams") }}</span>
        </div>
        <div>
          <router-link to="/c/exams" class="text-decoration-none primary--text fw-bold">
          <span role="button" class="f12">{{
              $t("Show all")
          }}</span>
          </router-link>
        </div>
      </div>

      <div class="d-flex flex-row">
        <v-slide-group class="py-4" center-active show-arrows>
          <v-slide-item v-for="(item, i) in exams" :key="i" class="my-2">
            <v-card 
              class="p-3 bg-white rounded mx-2 d-flex flex-row align-items-center justify-content-start border"
              @click="handleClickExam(item)"
              min-width="250"
              flat
            >
              <v-avatar size="40" v-if="item.elogo">
                <img :src="item.elogo" :alt="item.etitle" />
              </v-avatar>
              <b-icon icon="book" width="40" height="40" class="bg-light pa-3 rounded-circle" variant="primary" v-else></b-icon>
              <div class="ms-3">
                <span class="d-block f14 fw-bold">{{ item.etitle }}</span>
                
                <span class="d-block f13 grey--text">{{ item.drFirstName }} {{ item.drLastName }}</span>

                <div v-if="item.estatus == 'PAID' || item.estatus == 'FREE'">
                  <span class="d-block primary--text f12" v-if="item.estatus == 'FREE'">
                    {{ $t("FREE") }} ({{$t("Not answered")}})
                  </span>
                  <span class="d-block primary--text f12" v-else-if="item.estatus == 'PAID'">
                    {{ $t("PAID") }} ({{$t("Not answered")}})
                  </span>
                </div>
                <div v-else-if="item.estatus == 'PAYMENT_PENDING'">
                  <span class="red--text f12" >
                    {{ $t("PAYMENT PENDING") }} ({{(item.eprice || 0) | VMask(currencyMask)}} {{ $t("RIAL") }})
                  </span>
                </div>

                <span class="d-block green--text f12" v-else-if="item.estatus == 'ANSWERED'">
                  {{ $t("ANSWERED") }}
                </span>
              </div>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </div>
    </div>

    <!-- ---------------------------------------------------------------------- -->
    <!-- Exercise -->
    <!-- ---------------------------------------------------------------------- -->
    <div v-if="exercises && exercises.length > 0">
      <div class="d-flex justify-content-between mt-5">
        <div>
          <span class="f14 font-weight-bold">{{ $t("Training") }}</span>
        </div>
        <router-link to="/c/exercises" class="text-decoration-none primary--text fw-bold">
          <span role="button" class="f12">{{
              $t("Show all")
          }}</span>
        </router-link>
      </div>

      <div class="d-flex flex-row">
        <v-slide-group class="py-4" center-active show-arrows>
          <v-slide-item v-for="(item, i) in exercises" :key="i" class="my-2">
            <v-card 
              class="p-3 bg-white rounded mx-2 d-flex flex-row align-items-center justify-content-start border"
              @click="handleClickExercise(item)"
              flat
              min-width="250"
            >
              <v-avatar size="40" v-if="item.elogo">
                <img :src="item.elogo" :alt="item.etitle" />
              </v-avatar>
              <b-icon icon="book" width="40" height="40" class="bg-light pa-3 rounded-circle" variant="primary" v-else></b-icon>

              <div class="ms-3">
                <span class="d-block f14 fw-bold">{{ item.etitle }}</span>
                
                <span class="d-block f13 grey--text">{{ item.drFirstName }} {{ item.drLastName }}</span>
              </div>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </div>
    </div>

    
    <!-- ---------------------------------------------------------------------- -->
    <!-- Latest Doctors -->
    <!-- ---------------------------------------------------------------------- -->
    <div v-if="doctors != null">
      <div class="mt-5 d-flex justify-content-between">
        <div>
          <span class="f14 font-weight-bold">{{ $t("Latest specialists") }}</span>
        </div>
        <div cols="auto">
          <router-link to="/c/specialists" class="text-decoration-none primary--text fw-bold">
          <span role="button" class="f12">{{
              $t("Show all")
          }}</span>
          </router-link>
        </div>
      </div>
      <v-sheet class="mt-4" elevation="0">
        <v-slide-group show-arrows>
          <v-slide-item v-for="(item, i) in doctors" :key="i">
            <v-card flat class="ma-4 text-center" @click.prevent="openDrBottomSheet(item.mobile)">
              <v-avatar size="72">
                <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="item.pic != null ? item.pic : require('@/assets/images/profile.jpg')"></v-img>
              </v-avatar>
              <span class="f15 max-3-line mt-4">
                {{ item.firstname }} {{ item.lastname }}
              </span>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>

    
    <!-- ---------------------------------------------------------------------- -->
    <!-- Questions -->
    <!-- ---------------------------------------------------------------------- -->
    <div class="mt-5 d-flex justify-content-between" v-if="questions && questions.length > 0">
      <div>
        <span class="f14 font-weight-bold">{{
            $t("Frequently questions")
        }}</span>
      </div>
      <div>
        <router-link to="/c/questions" class="text-decoration-none primary--text fw-bold">
          <span role="button" class="f12">{{
              $t("Show all")
          }}</span>
        </router-link>
      </div>
    </div>
    <div class="mt-2" v-if="questions && questions.length > 0">
        <v-carousel height="auto" cycle hide-delimiter-background :show-arrows="false" :interval="4000" light>
          <v-carousel-item v-for="(item, i) in questions" :key="i">
            <v-card class="mx-2 mt-2 mb-12 d-flex flex-column justify-space-between border" elevation="0" hover ripple :to="`/c/questions/${item.id}`">
              <v-card-title class="d-flex justify-space-between align-items-center">
                <span v-if="item.answers" class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> {{ item.answers.length }}
                  {{ $t("Answer") }}</span>
                <span v-else class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> 0 {{ $t("Answer") }}</span>
                <span class="f13 font-weight-light"> {{ item.createDate | moment("from", "now") }} </span>
              </v-card-title>

              <v-card-text>
                <p class="f14 text-justify" v-html="item.question"></p>
              </v-card-text>
              
              <v-divider dark class="m-0"></v-divider>

              <v-card-actions class="d-flex justify-space-between pt-2 align-center">
                <div v-if="item.answers && item.answers.length">
                  <div class="d-flex flex-row align-items-center">
                    <section class="d-flex flex-row avatar-group right-to-left">
                      <span v-for="(answer, index) in item.answers.slice(0, 3)" :key="index"
                        class="avatars-group__item avatar">
                        <v-avatar size="36" color="primary" class="bordered">
                          <img :src="answer.doctorProfilePic" :alt="answer.doctorFullName ? answer.doctorFullName : ''"
                            v-if="answer.doctorProfilePic && index < 2" />

                          <span v-else-if="index < 2" class="white--text f12">
                            {{ answer.doctorFullName ? answer.doctorFullName.slice(0, 1) : "" }}
                          </span>

                          <span v-else class="white--text f14">
                            ...
                          </span>
                        </v-avatar>
                      </span>
                    </section>
                    <div>
                      <span class="f12 font-weight-normal ps-1" v-if="item.answers.length == 1">
                        {{ item.answers[0].doctorFullName ? item.answers[0].doctorFullName : '' }}
                      </span>
                      <span class="f12 font-weight-normal ps-1" v-if="item.answers.length > 1">
                        {{ item.answers.length }} {{ $t('specialist') }}
                      </span>
                    </div>
                  </div>
                </div>
                
                <div v-else>
                  <span class="bg-harmonies text-paris-pink p-2 rounded-lg f12">
                    {{ $t("Not answered") }}
                  </span>
                </div>
                <div>
                  <div class="text-left mt-1">
                    <div class="d-flex d-inline-flex align-items-center border border-primary rounded primary--text" v-if="item.userLikeQuestions && !item.userLikeQuestions.find(x => x.mobile == $store.getters.mobileNumber)">
                      <iconly type="bold" name="heart" class="ms-1 me-0 wh-20"></iconly>
                      <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                        {{
                            item.userLikeQuestions.length
                        }}
                      </span>
                      <span v-else class="me-1 f12">0</span>
                    </div>
                    <div class="d-flex d-inline-flex align-items-center primary rounded white--text" v-else>
                      <iconly type="bold" name="heart" class="ms-1 me-0 wh-20" color="white"></iconly>
                      <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                        {{
                            item.userLikeQuestions.length
                        }}
                      </span>
                      <span v-else class="me-1 f12">0</span>
                    </div>

                    <div class=" d-flex d-inline-flex align-items-center border border-primary rounded primary--text ms-2">
                      <iconly type="bold" name="show" class="ms-1 me-0 wh-20"></iconly>
                      <span class="me-1 f12">{{ item.userViews.length }}</span>
                    </div>
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </div>

    <!-- ---------------------------------------------------------------------- -->
    <!-- Posts -->
    <!-- ---------------------------------------------------------------------- -->
    <div v-if="explore && explore.length > 0">
      <div class="mt-0 d-flex justify-content-between">
        <div>
          <span class="f14 font-weight-bold">{{ $t("Last posts") }}</span>
        </div>
        <div>
          <router-link to="/c/contents" class="text-decoration-none primary--text fw-bold">
          <span role="button" class="f12">{{
              $t("Show all")
          }}</span>
          </router-link>
        </div>
      </div>
      <div>
        <v-slide-group :show-arrows="true">
          <v-slide-item v-for="(item, i) in explore" :key="i">
            <v-card class="ma-3 border rounded-lg" height="auto" :to="`/c/contents/${item.type.toLowerCase()}/${item.id}`" elevation="0" >
              <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="item.picture" :aspect-ratio="1 / 1" width="250" class="d-flex align-items-center justify-content-center">
                <div class="text-white w-100 text-center">
                  <i class="fas fa-play p-4 bg-black-50 rounded-pill" v-if="item.type == 'VIDEO'"></i>
                </div>
              </v-img>

              <v-card-title class="f13 single-line pb-0">
                {{ item.title }}
              </v-card-title>

              <v-card-actions @click.prevent="openDrBottomSheet(item.publisher)" class="pt-0">
                <v-list-item two-line class="p-0">
                  <v-list-item-avatar>
                    <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="item.publisherPic"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="f12 font-weight-bold primary--text">{{ item.publisherName }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="f10">{{ item.createDate | moment("from", "now") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </div>
    </div>

    <!-- ---------------------------------------------------------------------- -->
    <!-- Podcasts -->
    <!-- ---------------------------------------------------------------------- -->
    <div v-if="podcasts && podcasts.length > 0">
      <div class="mt-5 d-flex justify-content-between">
        <div>
          <span class="f14 font-weight-bold">{{ $t("Last podcasts") }}</span>
        </div>
        <div>
          <router-link to="/c/podcasts" class="text-decoration-none primary--text fw-bold">
          <span role="button" class="f12">{{
              $t("Show all")
          }}</span>
          </router-link>
        </div>
      </div>
      <v-sheet class="mt-4" flat>
        <v-slide-group show-arrows>
          <v-slide-item v-for="(item, i) in podcasts" :key="i">
            <v-card class="d-flex flex-row align-items-center mb-2 pe-2 ps-0 border rounded-lg" :to="`/c/contents/podcast/${item.id}`" elevation="0" min-width="300">
              <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="item.picture != null ? item.picture : require('@/assets/images/placeholder.png')" :aspect-ratio="1 / 1" max-width="150"></v-img>
              <div class="d-flex flex-column ms-4 me-0">
                <p class="f14 font-weight-bold" v-html="item.title "></p>
                
                <span class="f14 font-weight-bold text-blue"  @click.prevent="openDrBottomSheet(item.publisher)">{{item.publisherName}}</span>
                <span class="f13 font-weight-light align-items-center d-flex grey--text">
                  <i class="fal fa-clock me-1 ms-0"></i>
                  {{ item.createDate | moment("from", "now") }}
                </span>
              </div>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>

    
    <BshDoctor ref="drBottomSheet" />

  </section>
</template>
<script>
import BshDoctor from "@/components/DoctorProfile.vue";
import apiService from "@/http/ApiService.js";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

  const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
  });

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
export default {

  components: {
    LineChartGenerator,
    BshDoctor
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 300,
    },
    cssClasses: {
      type: String,
      default: "",
    },
    styles: {
      type: Object,
      default: () => { },
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currencyMask,

      MoodGifShow: false,
      moods: [],
      slideshow: [],
      exams: [],
      exercises: [],
      doctors: [],
      questions: [],
      explore: [],
      podcasts: null,
      sheet: false,
      option: [{ value: "week", text: "هفته" }],

      chartData: {
        labels: [
          moment().subtract(6, "days").format("dddd"),
          moment().subtract(5, "days").format("dddd"),
          moment().subtract(4, "days").format("dddd"),
          moment().subtract(3, "days").format("dddd"),
          moment().subtract(2, "days").format("dddd"),
          moment().subtract(1, "days").format("dddd"),
          "امروز",
        ],
        datasets: [
          {
            label: "وضعیت روحی",
            backgroundColor: ["#20B1EA"],
            data: [0, 0, 0, 0, 0, 0, 0],
            radius: 5,
            borderWidth: 5,
            hoverBorderWidth: 25,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              display: true,
              font: {
                size: 14,
                family: "IRANSans",
              },
            },
          },

          x: {
            ticks: {
              font: {
                size: 18,
                family: "IRANSans",
              },
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              // This more specific font property overrides the global property
              font: {
                size: 14,
                family: "IRANSans",
              },
            },
          },
          tooltip: {
            enabled: true,
            titleFont: {
              size: 10,
              family: "IRANSans",
            },
            bodyFont: {
              size: 11,
              family: "IRANSans",
            },

            footerFont: {
              size: 12,
              family: "IRANSans",
            },
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";
                if (context.parsed.y !== null) {
                  label = "تعداد: " + Math.abs(context.parsed.y);
                }
                return label;
              },
            },
          },
        },
      },
    };
  },
  methods: {
    handleClickExam(exam) {
      this.$router.push({name: 'ExamDetails', params: {obj: {...exam}, code: exam.code}})
    },
    handleClickExercise(exercise) {
      this.$router.push({name: 'ExerciseDetails', params: {obj: {...exercise}, id: exercise.eid}})
    },
    openDrBottomSheet(mobile){
      this.$refs.drBottomSheet.openWithDoctorMobile(mobile);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    SlideShowListAll() {
      apiService.SlideShowListAll().then((response) => {
        this.slideshow = response.data;
      });
    },
    ExamExecrsice_Exams() {
      apiService.gatLatestExamExerciseActive("EXAM", 0, 5).then((response) => {
        this.exams = response.data.content;
      });
    },
    ExamExecrsice_Exercise() {
      apiService.gatLatestExamExerciseActive("EXERCISE", 0, 5).then((response) => {
        this.exercises = response.data.content;
      });
    },
    LatestDoctors() {
      apiService.LatestDoctors().then((response) => {
        this.doctors = response.data;
      });
    },
    AssignQuestion() {
      apiService.ExamCheckIsAssign(23).then((response) => {
        if (response.data.assigned === true) {
          const url = `${process.env.VUE_APP_EX_URL}/exam/${response.data.code}`;
          this.$router.push({name: 'ExamDetails', params: {obj: {...23}, code: response.data.code}})
        } else {
          apiService.AssignMe(23).then((response) => {
            this.$router.push({name: 'ExamDetails', params: {obj: {...response.data.examId}, code: response.data.code}})
          });
        }
      });
    },
    UsersQuestions() {
      apiService.UserQuestion().then((response) => {
        this.questions = response.data;
      });
    },
    ExporePosts() {
      apiService.ExplorePosts(0, 4).then((response) => {
        this.explore = response.data;
      });
    },
    PodCastPublished() {
      apiService.getPodcastspublishPaging(0, 4).then((response) => {
        this.podcasts = response.data.data;
      });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', false);
    this.$store.commit('update_helloActive', true);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', false);

    this.SlideShowListAll();
    this.ExamExecrsice_Exams();
    this.ExamExecrsice_Exercise();
    this.LatestDoctors();
    this.UsersQuestions();
    this.ExporePosts();
    this.PodCastPublished();
  },
};
</script>
<style>
div.v-window__next > button.v-btn > span.v-btn__content > i.v-icon {
  color: #ffffff !important;
}
div.v-window__prev > button.v-btn > span.v-btn__content > i.v-icon  {
  color: #ffffff;
}

</style>
